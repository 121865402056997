<template>
  <div class="ddj-advertiser">
    <div class="main-Title bgff"><h2>Advertiser</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form :model="filter" label-width="150px" size="small">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="advertiserName:">
                <el-input
                  v-model="filter.advertiserName"
                  placeholder="Please enter advertiser Name"
                  class="mr10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" size="mini" @click="filterFun('filter')">Search</el-button>
              <el-button type="success" size="mini" @click="add()">Add</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          style="width:100%;"
          height="70vmin"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="广告主名称" prop="advertiserName"></el-table-column>
          <el-table-column label="广告主编码" prop="advertiserCode"></el-table-column>
          <el-table-column label="添加时间" prop="createTime"></el-table-column>
          <el-table-column label="修改时间" prop="updateTime"></el-table-column>
          <!-- <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjAdvertiserCon from '../../controllers/DDJ/advertiser/list';
  export default {
    name: 'ddjAdvertiser',
    ...ddjAdvertiserCon,
  };
</script>

<style></style>
