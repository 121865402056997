import { getAdvertiserPage, handleAdvertiserDel } from '@/api/DDJ/advertiser/index';
export default {
  components: {},
  data() {
    return {
      filter: {
        advertiserName: '',
      },
      options: {},
      list: [
        {
          offerId: '1',
        },
      ],
      loading: {
        list: false,
      },
      pages: {
        pageNum: 1,
        pageSize: 10,
      },
      pagesTotal: 0,
      isShowPage: false,
      rules: {},
    };
  },
  mounted() {
    this.filterFun();
  },
  computed: {},
  methods: {
    // 获取列表
    filterFun(types) {
      if (this.filter.advertiserName) {
        this.filter.advertiserName = this.filter.advertiserName.trim();
      }
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      getAdvertiserPage(query).then((res) => {
        let data = res.result;
        this.list = data.records;
        this.pagesTotal = data.total;
        this.isShowPage = true;
        this.loading.list = false;
      });
    },
    // 删除操作
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          console.log(id);
          handleAdvertiserDel({
            id: id,
          }).then((res) => {
            console.log(res);
            this.filterFun();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 添加路由
    add() {
      this.$router.push({
        path: '/click/advertiser/add',
      });
    },
    // 编辑路由
    edit(id) {
      this.$router.push({
        path: '/click/advertiser/edit',
        query: {
          id: id,
        },
      });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.filterFun();
    },
  },
};
